<template>
  <div class="main-box">
    <!-- 面包屑 -->
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">购物车</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="shopBar">
      <!-- 全部商品 -->
      <div class="moduletitle">
        <p class="title"><span></span>全部商品</p>
        <div class="rightmethod"></div>
      </div>
      <a-table
        :columns="columns"
        :data-source="data"
        :rowKey="(item,index)=>{return item.shopId}"
        :row-selection="{
          onChange
        }"
        :expanded-row-keys.sync="expandedRow"
        :pagination="false"
      >
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            title="您确定要从购物车删除该商品吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delItem(record)"
          >
            <p class="delbtn">删除</p>
          </a-popconfirm>
        </template>
        <template slot="couponPrice" slot-scope="text">
          ￥{{text.toFixed(2)}}
        </template>
        <template slot="count" slot-scope="text,row">
          <span v-if="(row.type == 1 || row.type == 4) && row.buyLimitNum != 1">x{{text}}</span>
          <div class="count" v-else>
            <a-icon class="icon" :style="row.count == 1?{cursor:'not-allowed'}:''" type="minus" @click="changeCount(-1,row)" />
            <a-input-number
              id="inputNumber"
              v-model="row.count"
              :precision="0"
              :parser="limitNumber"
              :min="1"
              :max="9999"
              @change="changeCountInput($event,row)"
            />
            <a-icon class="icon" type="plus" @click="changeCount(1,row)" />
          </div>
        </template>
        <template slot="totalPrice" slot-scope="text">
          ￥{{text.toFixed(2)}}
        </template>
        <template slot="name" slot-scope="text, record">
          <div class="product" @click="goShopDetail(record)">
            <img :src="record.listPhoto" alt=""/>
            <div class="message">
              <p class="name">{{record.name}}</p>
              <p class="type" v-if="record.type == 1 && record.openTimeId">{{record.className}} {{record.startTime}}至{{record.endTime}}</p>
              <p class="type" v-else-if="record.type == 1">{{record.courseCategory}} | {{record.courseHour}}</p>
              <p class="type" v-else-if="record.type == 4">{{record.answerNumber}}</p>
            </div>
          </div>
        </template>
      </a-table>
      <!-- <p class="couponPrice">优惠金额：<span>¥10.00</span></p> -->
      <p class="allPrice" v-show="data.length">总计：<span>¥{{totalPrice.toFixed(2)}}</span></p>
      <div class="footer" v-show="data.length">
        <div class="left">
          <p class="shopNum">已选择<span>{{expandedRow.length}}</span>件商品</p>
          <a-popconfirm
            title="您确定要清空购物车吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delShopCar"
          >
            <p class="delShopCar">清空购物车</p>
          </a-popconfirm>
        </div>
        <div class="goPay all-btn-small" @click="toCommit">去结算</div>
      </div>
    </div>
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar.vue";
const columns = [
  {
    title: '商品',
    dataIndex: 'name',
    align: 'center',
    width: '40%',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '单价',
    dataIndex: 'couponPrice',
    align: 'center',
    scopedSlots: { customRender: 'couponPrice' }
  },
  {
    title: '数量',
    dataIndex: 'count',
    align: 'center',
    scopedSlots: { customRender: 'count' }
  },
  {
    title: '小计',
    dataIndex: 'totalPrice',
    align: 'center',
    scopedSlots: { customRender: 'totalPrice' }
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'operation' }
  },
];

export default {
  components:{ Sidebar },
  data() {
    return {
      data: [],
      columns,
      expandedRow: [],
      timer:null,
      totalPrice:0, // 总计
    };
  },
  methods:{
    // 跳转商品详情
    goShopDetail(item){
      if (item.productType == 1 || item.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(item.productId + "") +
            "&code=" +
            item.code
        );
        if (this.$route.query.productId) {
          // this.getCourseDetail();
          this.getShopList();
          // 获取优惠券列表
          this.getCouponList(1);
        }
      } else if (
        item.productType == 2 ||
        item.productType == 3 ||
        item.type == 2 ||
        item.type == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      } else if (item.productType == 4 || item.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      }
    },

    // methods 正则替换小数点
    limitNumber(value) {
      if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(/\./g, '') : 0
      } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(/\./g, '') : 0
      } else {
        return 0
      }
    },
    // 去结算
    async toCommit(){
      if(!this.expandedRow.length){
        return this.$message.error('请选择要结算的商品')
      }
      let productList = [];
      let productIds = [];
      this.expandedRow.map(item=>{
        productList.push(item);
        productIds.push(item.productId);
      })
      await this.$ajax({
        url:'/hxclass-pc/pc-mall/shop/ground',
        method:'get',
        params:{
          productIds:productIds.join(',')
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$store.commit('updatelist',productList);
          this.$store.commit('updateType',1);
          this.$store.commit('updateInvoiceId',null);
          this.$store.commit('updateShortInvoiceId',null);
          this.$store.commit('updateAddress',null);
          this.$store.commit('updateCouponList',null);
          this.$router.push('/order/confirmOrder')
        }else{
          // this.$emit('getShopCarList');
          this.getShopList();
          this.$message.error('当前购物车包含已下架商品，请重新选择')
        }
      })
    },
    onChange(selectedRowKeys, selectedRows)  {
      this.expandedRow = selectedRows;
    },
    // 输入商品数量
    changeCountInput(num,row) {
      if(!num){
        row.count = 1;
      }
      this.editProduce(row)
    },
    // 改变商品数量
    changeCount(i,row) {
      if (i < 0) {
        if (row.count != 1) {
          this.$set(row, "count", row.count + i);
        }else {
          return 
        }
      } else {
        this.$set(row, "count", row.count + i);
      }
      if(i>9999){
        return
      }
      this.editProduce(row)
    },
    // 编辑商品
    editProduce(e){
      if(e.count>9999){
        return
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.$ajax({
          url:'/hxclass-pc/pc-mall/shop/update',
          method:'put',
          params:{
            count: e.count, // 数量
            couponCode: '', // 优惠劵编码
            openTimeId: e.openTimeId, // 开班设置id
            price: e.couponPrice, // 价格
            productId: e.productId, // 商品id
            shopId: e.shopId, // 购物车
            type: e.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
            userNo: this.$store.state.userInfo.userId
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.$refs['sidebar'].getShopList()
            // 修改选中数组
            this.expandedRow.map((item,index)=>{
              if(item.shopId == e.shopId){
                this.expandedRow[index].count = e.count;
                this.expandedRow[index].totalPrice = item.couponPrice * e.count;
              }
            })
            this.data.map((item,index)=>{
              if(item.shopId == e.shopId){
                this.data[index].count = e.count;
                this.data[index].totalPrice = item.couponPrice * e.count;
              }
            })
            // this.$emit('getShopCarList');
          }else{
            this.getShopList();
            this.$message.error('修改失败');
          }
        })
      },500)
    },
    // 清空购物车
    delShopCar(){
      this.$ajax({
        url:"/hxclass-pc/pc-mall/shop/delete",
        method:"delete"
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.expandedRow = [];
          this.$refs['sidebar'].getShopList()
          this.data = [];
          // this.shopList?.map(item=>{
          //   this.$set(item,'noSHop',0);
          // })
          // this.$emit('getShopCarList');
          // this.deleteModel = false;
          // // console.log(this.$parent)
          // this.showPopup = false;
        }
      })
    },
    // 删除操作
    delItem(e){
      this.$ajax({
        url:'/hxclass-pc/pc-mall/shop/update',
        method:'put',
        params:{
          count: 0, // 数量
          couponCode: '', // 优惠劵编码
          openTimeId: e.openTimeId, // 开班设置id
          price: e.couponPrice, // 价格
          productId: e.productId, // 商品id
          shopId: e.shopId, // 购物车
          type: e.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
          userNo: this.$store.state.userInfo.userId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$refs['sidebar'].getShopList()
          // 修改选中数组
          this.expandedRow.map((item,index)=>{
            if(item.shopId == e.shopId){
              this.expandedRow.splice(index,1)
            }
          })
          // this.$emit('getShopCarList');
          this.getShopList();
        }else{
          this.getShopList();
          this.$message.error('修改失败');
        }
      })
    },
    getShopList() {
      let list = this.$ajax({
        url: '/hxclass-pc/pc-mall/shop/list',
        method: 'get'
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.data = res.data
        }
      })
      return list
    }
  },
  created() {
    if(this.$store.state.userInfo.userId){
      this.getShopList();
    }
  },
  watch: {
    expandedRow:{
      handler(newVal,oldVal){
        if(newVal.length){
          let totalPrice = 0;
          newVal.map(item => {
            totalPrice += item.totalPrice;
          })
          this.totalPrice = totalPrice;
        }else{
          this.totalPrice = 0;
        }
      },
      deep:true
    }
  }
}
</script>

<style scoped lang="less">
  .main-box {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .count {
    width: 108px;
    height: 41px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    border-radius: 2px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    margin: 0 auto;
    .icon {
      font-size: 14px;
      color: #666666;
    }
    ::v-deep.ant-input-number-handler-wrap {
      display: none;
    }
    ::v-deep.ant-input-number {
      height: 20px;
      width: 77px;
      border: 0;
    }
    ::v-deep.ant-input-number-input {
      height: 20px;
      text-align: center;
    }
    ::v-deep.ant-input-number:focus {
      box-shadow: none !important;
    }
  }
  .shopBar{
    background: #ffffff;
    margin-top: 22px;
    border-radius: 5px;
    padding: 40px 40px 0;
    // 表头样式
    /deep/.ant-table-header-column{
      font-size: 16px;
    }
    // 标题
    .moduletitle {
      height: 36px;
      font-size: 24px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #15b7dd;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .title{
        span {
          display: inline-block;
          background-color: #15b7dd;
          height: 19px;
          width: 4px;
          border-radius: 7px;
          margin-right: 12px;
        }
      }
    }
    @media screen and (max-width:16000px) {
      .moduletitle{
        font-size: 22px;
      }
    }
  }
  .delbtn{
    font-size: 14px;
    font-family: PingFang HK-Regular, PingFang HK;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    cursor: pointer;
  }
  .delbtn:hover{
    color: #15b7dd;
  }
  .product{
    cursor: pointer;
    display: flex;
    img{
      width: 116px;
      height: 87px;
      background-repeat: no-repeat;
      object-fit: cover;
    }
    .message{
      text-align: left;
      padding: 2px 18px;
      .name{
        font-size: 14px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        /* 溢出隐藏 */
        overflow: hidden;
        display: -webkit-box;
        /*这里写显示的行数 */
        -webkit-line-clamp: 2;
        /* vertical表示垂直排列 */
        -webkit-box-orient: vertical;
      }
      .type{
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1E97B4;
        line-height: 21px;
      }
    }
  }
  .couponPrice,.allPrice{
    text-align: right;
    font-size: 16px;
    font-family: PingFang HK-Regular, PingFang HK;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    span{
      display: inline-block;
    }
  }
  .couponPrice{
    margin-top: 155px;
  }
  .allPrice{
    line-height: 36px;
    margin-top: 16px;
    span{
      font-size: 24px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #EC6C01;
      line-height: 36px;
    }
  }
  .footer{
    width: calc(100% + 80px);
    padding: 0 40px;
    margin-left: -40px;
    padding-bottom: 21px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px -2px 10px 0px rgba(0,0,0,0.0400);
    .left{
      display: flex;
      .shopNum{
        font-size: 16px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
        span{
          color: #EC6C01;
        }
      }
      .delShopCar{
        cursor: pointer;
        margin-left: 24px;
        font-size: 16px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #15B7DD;
        line-height: 19px;
      }
    }
    .goPay{
      width: 138px;
      height: 60px;
      line-height: 60px;
      border-radius: 0;
      font-size: 18px;
      font-family: PingFang HK-Medium, PingFang HK;
      font-weight: 500;
      color: #FFFFFF;
      box-shadow: 0px 4px 4px 0px rgba(49,188,221,0.25);
    }
  }
</style>